<template>
    <div class="flex items-center justify-center w-full">
        <!-- pc端 -->
        <div class="flex justify-between mb-6 page_box pc_show">
            <div class="bg-white page_left">
                <div class="flex items-center justify-start drive_title_box">
                    <img src="../../../../assets/images/serve/wallpaper_icon.png" alt />
                    <div class="items-center drive_title">壁纸下载</div>
                </div>
                <ul class="sm:pt-4">
                    <li v-for="(item, index) in menu" :key="item.wallpaperId"
                        :class="current == index ? 'text-primary' : ''"
                        class="text-left cursor-pointer sm:py-2 hover:text-primary" @click="changeIndex(item, index)">{{
                            item.wallpaperName }}</li>
                </ul>
            </div>
            <div class="bg-white page_right">
                <div class="text-left text-gray-400 current_title sm:py-6 sm:font-bold" v-if="menu.length > 0">{{
                    menu[current].wallpaperName }}</div>
                <div class="w-full current_content sm:pt-4">
                    <div class="flex flex-wrap w-full" v-if="list.length > 0">
                        <div class="flex items-center justify-center item_box" v-for="item in list" :key="item.wallpaperId">
                            <div class="relative flex items-center justify-center img_box">
                                <!-- <img src="../.././../../assets/images/serve/picture.png" alt /> -->
                                <img :src="item.image" alt @click="review(item.image)" />
                                <div class="absolute z-10 icon_box">
                                    <div class="flex items-center cursor-pointer sm:mb-1" @click="collection(item)"
                                        v-if="item.ifCollect == false">
                                        <img src="../../../../assets/images/serve/collection.png" alt />
                                        <div class="text-white" style="white-space:nowrap; ">收藏</div>
                                    </div>
                                    <div class="flex items-center cursor-pointer sm:mb-1" @click="collection(item)"
                                        v-if="item.ifCollect == true">
                                        <img src="../../../../assets/images/common/collection_active.png" alt
                                            v-if="item.ifCollect == true" />
                                        <div class="text-primary" style="white-space:nowrap; ">已收藏</div>
                                    </div>

                                    <div class="flex items-center cursor-pointer" @click="download(item.image)">
                                        <img src="../../../../assets/images/serve/download.png" alt />
                                        <div class="text-white" style="white-space:nowrap; ">下载</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-left text-gray-500 null" v-else>暂无相关壁纸</div>
                </div>
            </div>
        </div>

        <!-- 手机端 -->
        <div class="flex justify-between mb-6 page_box phone_show">
            <div class="w-full">
                <div class="flex items-center justify-start drive_title_box">
                    <img src="../../../../assets/images/serve/wallpaper_icon.png" alt />
                    <div class="items-center font-bold drive_title">壁纸下载</div>
                </div>
                <div class="text-left text-gray-500 path">首页-自助服务-壁纸下载</div>
                <div class="w-full scorll_box">
                    <span v-for="(item, index) in menu" style="display:inline-block;width:25%" :key="item.wallpaperId"
                        :class="current == index ? 'text-primary' : ''"
                        class="text-left cursor-pointer sm:py-2 hover:text-primary" @click="changeIndex(item, index)">{{
                            item.wallpaperName }}</span>
                </div>
                <div class="w-full current_content sm:pt-4">
                    <div class="flex flex-wrap w-full" v-if="list.length > 0">
                        <div class="flex items-center justify-center item_box" v-for="item in list" :key="item.wallpaperId">
                            <div class="relative flex items-center justify-center img_box">
                                <!-- <img src="../.././../../assets/images/serve/picture.png" alt /> -->
                                <img :src="item.image" alt @click="review(item.image)" />
                                <div class="absolute z-10 icon_box">
                                    <div class="flex items-center cursor-pointer">
                                        <img src="../../../../assets/images/serve/collection.png" alt
                                            @click="collection(item)" v-if="item.ifCollect == false" />
                                        <img src="../../../../assets/images/common/collection_active.png" alt
                                            v-if="item.ifCollect == true" />
                                        <img @click="download(item.image)"
                                            src="../../../../assets/images/serve/download.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-left text-gray-500 null" v-else>暂无相关壁纸</div>
                </div>
            </div>
        </div>
        <Popup :isShow="isShow" @close="closeSearch">
            <template>
                <div>
                    <img :src="reviewImg" alt />
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import Popup from "@/components/Popup/Popup"
export default {
    data() {
        return {
            current: 0,
            parentId: 0,
            menu: [],
            list: [],
            isShow: false,
            reviewImg: ""
        };
    },
    components: {
        Popup
    },
    created() {
        this.getWallpaper(0)
        this.$EventBus.$on('wallpaperOperation', (id) => {
            let index = this.list.findIndex(item => {
                return item.wallpaperId == id
            })
            index > -1 ? this.list[index].ifCollect = true : ""
        })
    },
    methods: {
        changeIndex(item, index) {
            this.getWallpaper(item.wallpaperId)
            this.current = index
        },
        getWallpaper(parentId) {
            let url = this.$requestPath.getWallpaper + `?parentId=${parentId}`
            this.$request({
                methods: "get",
                url,
                data: {}
            }).then(res => {
                if (res.code == 0) {
                    if (parentId == 0) {
                        this.menu = []
                        this.menu.push(...res.data)
                        this.getWallpaper(this.menu[0].wallpaperId)
                    } else {
                        this.list = []
                        this.list.push(...res.data)
                        console.log('list', this.list)
                    }
                } else {
                    this.$message.error(res.msg);
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        },
        download(path) {
            this.canvasDownload(path)
        },
        collection(item) {
            this.$store.dispatch("collectOpration", { type: 1, relationId: item.wallpaperId, $EventBus: this.$EventBus })
        },
        // 关闭弹窗
        closeSearch() {
            this.isShow = false
        },
        // 图片预览
        review(url) {
            this.isShow = true
            this.reviewImg = url
        }
    },
};
</script>

<style lang='scss' scoped>
.page_box {
    width: 100%;
    max-width: var(--max-width);
    // height: 3000px;
    padding: 0 var(--margin-base);

    .page_left {
        width: 13.75rem;
        padding: 0 1.875rem;
        // max-width: 12.5rem;
    }

    .page_right {
        width: calc(100% - 15rem);
    }
}

.current_title {
    border-bottom: 0.0625rem solid var(--border-light);
}

.item_box {
    width: 33.33%;
    overflow: hidden;

    .img_box {
        width: 98%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.item_box .item:nth-of-type(3) {
    justify-content: flex-end;
}

@media screen and (max-width: 640px) {
    .phone_show {
        display: block;
    }

    .pc_show {
        display: none;
    }

    .drive_title_box {
        margin: 0.625rem 0px;

        img {
            width: 1.25rem;
        }

        .drive_title {
            font-size: 0.9375rem;
            margin-left: 0.375rem;
            display: flex;
            padding-top: 0.0625rem;
        }
    }

    .path {
        font-size: 0.875rem;
        margin-bottom: 0.3125rem;
    }

    .scorll_box {
        height: 1.875rem;
        line-height: 1.875rem;
        overflow-x: scroll;
        white-space: nowrap;
        font-weight: bold;
        -ms-overflow-style: none;
        /* IE 10+ */
        scrollbar-width: none;

        /* Firefox */
        ::-webkit-scrollbar {
            display: none;
            /* Chrome Safari */
        }
    }

    .current_content {
        margin-top: 0.625rem;

        .item {
            font-size: 0.8125rem;
        }
    }

    .item_box {
        margin-bottom: 0.25rem;

        .icon_box {
            bottom: 0.25rem;
            right: 0.25rem;

            img {
                width: 0.75rem;
                margin-left: 0.3125rem;
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .phone_show {
        display: none;
    }

    .pc_show {
        display: flex;
    }

    .drive_title_box {
        margin: 0.625rem 0px;

        img {
            width: 1.4375rem;
        }

        .drive_title {
            font-size: 1rem;
            margin-left: 0.375rem;
            display: flex;
            padding-top: 0.0625rem;
        }
    }

    .current_title {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        font-size: 1.5625rem;
    }

    .current_content {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }

    .item_box {
        margin-bottom: 0.25rem;

        .icon_box {
            top: 0.625rem;
            left: 0.625rem;

            img {
                width: 0.9375rem;
            }

            div {
                font-size: 0.1875rem;
                margin-left: 0.125rem;
            }
        }
    }
}
</style>